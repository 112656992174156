import axios from "@/config/http.js";

// 部门管理
export const APID = {
  // 部门首页（树形结构数据）
  async pageIndex(params) {
    return await axios.get("/sysDept/list", { params });
  },
  // 新增部门
  async departmentAdd(params) {
    return await axios.post("/sysDept", params);
  },
  // 编辑部门
  async departmentEdit(params) {
    return await axios.put("/sysDept", params);
  },
  // 部门管理可用树形结构（用于用户那边做查询树）
  async departmentAvaTree() {
    return await axios.get("/sysDept/usableList");
  },
  // 删除部门
  async departmentDelete(deptId) {
    return await axios.delete(`/sysDept/${deptId}`);
  },
  // 启用部门
  async departmentPass(deptId) {
    return await axios.put(`/sysDept/start/${deptId}`);
  },
  // 禁用部门
  async departmentDisabled(deptId) {
    return await axios.put(`/sysDept/stop/${deptId}`);
  },
};
