const nameValidator = (rule, value, callback) => {
  if (value.length > 16) {
    callback("字典名称长度不能超过16位");
  }
  callback();
};
const descValidator = (rule, value, callback) => {
  if (value.length > 32) {
    callback("字典名称长度不能超过32位");
  }
  callback();
};

// 数据字典
export const DictionaryRule = {
  // 字典类型
  dictName: [
    { required: true, message: "请输入字典名称", trigger: "blur" },
    { validator: nameValidator, trigger: "change" },
  ],
  status: [{ required: true, message: "请选择状态", trigger: "blur" }],
  dictDescribe: [{ validator: descValidator, trigger: "change" }],
  // 详情
  dictCode: [{ required: true, message: "请输入字典编码", trigger: "blur" }],
  dictValue: [{ required: true, message: "请输入字典标签值", trigger: "blur" }],
};

// 用户管理
export const userRule = {
  // 用户昵称
  nickName: [
    { required: true, message: "请输入用户昵称", trigger: "blur" },
    { max: 32, message: "用户昵称最长字符串32位", trigger: "change" },
  ],
  deptId: [{ required: true, message: "请选择归属部门", trigger: "blur" }],
  phonenumber: [
    { max: 11, message: "手机号码最长字符串11位", trigger: "blur" },
    {
      pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
      message: "请输入正确的手机号码",
      trigger: "change",
    },
  ],
  email: [{ max: 32, message: "邮箱最长字符串32位", trigger: "blur" }],
  userName: [
    { required: true, message: "请输入用户名称", trigger: "blur" },
    { max: 32, message: "用户名称最长字符串32位", trigger: "change" },
  ],
  password: [
    { required: true, message: "请输入用户密码", trigger: "blur" },
    {
      pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/,
      message: "密码必须为至少8位,最大16位的数字字母组合",
      trigger: "blur",
    },
  ],
  status: [{ required: true, message: "请选择状态", trigger: "blur" }],
};

// 部门管理
export const departRule = {
  name: [
    { required: true, message: "请输入部门名称", trigger: "blur" },
    { max: 32, message: "部门名称最长为字符串32位", trigger: "change" },
  ],
  principal: [
    { max: 32, message: "负责人最长为字符串32位", trigger: "change" },
  ],
  phone: [{ max: 32, message: "联系电话最长为字符串32位", trigger: "change" }],
  email: [{ max: 32, message: "邮箱最长为字符串32位", trigger: "change" }],
  parentId: [{ required: true, message: "请选择上级部门", trigger: "blur" }],
  state: [{ required: true, message: "请选择状态", trigger: "blur" }],
};

// 添加评价体系
export const evaluationRule = {
  normName: [
    { required: true, message: "请输入指标名称", trigger: "blur" },
    { max: 16, message: "指标名称最长字符串16位", trigger: "change" },
  ],
  normType: [{ required: true, message: "请选择指标分类", trigger: "blur" }],
  normFullmark: [
    { required: true, message: "请输入指标满分", trigger: "blur" },
  ],
  markType: [{ required: true, message: "请选择打分方式", trigger: "blur" }],
  selection: [{ required: true, message: "请输入选择项", trigger: "blur" }],
  inspectionStandard: [
    { required: true, message: "请输入检验标准", trigger: "blur" },
    { max: 256, message: "检验标准最长字符串256位", trigger: "change" },
  ],
};
