<template>
  <div class="department-manage-container">
    <div class="top-container">
      <a-row>
        <a-form-model layout="inline" style="display: flex">
          <a-form-model-item label="部门名称">
            <a-input
              placeholder="请输入"
              v-model="queryFrom.name"
              @keyup.enter="queryBtn"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item label="状态">
            <a-select
              show-search
              placeholder="请选择"
              :getPopupContainer="
                (triggerNode) => handleDislocation(triggerNode)
              "
              :default-active-first-option="false"
              :style="{ width: '28.5rem' }"
              :not-found-content="null"
              v-model="queryFrom.state"
            >
              <a-select-option
                v-for="(item, i) in stateList"
                :value="item.value"
                :key="i"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <div class="right">
            <a-button type="reload" icon="redo" @click="handleReset"
              >重置</a-button
            >
            <a-button type="primary" icon="search" @click="queryBtn"
              >查询</a-button
            >
          </div>
        </a-form-model>
      </a-row>
    </div>
    <div class="bottom-container">
      <a-button class="mb2" type="primary" @click="openModal('add')"
        >新增</a-button
      >
      <a-table
        :columns="columns"
        :data-source="data"
        :key="data.key"
        :loading="loading"
        :scroll="{ y: 400 }"
        :defaultExpandedRowKeys="keysArr"
        :pagination="false"
      >
        <template slot="state" slot-scope="text,record">
          <a-switch
            :checked="text == 0 ? false : true"
            @change="(val) => handleSwitch(val, record.id)"
          />
          <!-- <span
            :style="{ color: `${getColor(getMenuName(stateList, text))}` }"
            >{{ getMenuName(stateList, text) }}</span
          > -->
        </template>
        <template slot="operation" slot-scope="text, record">
          <span
            class="hover"
            v-if="record.state == 1"
            :style="{ color: `${getColor('主题')}` }"
            @click="openModal('edit', record)"
            >修改</span
          >
          <span
            class="hover"
            v-if="record.state == 1"
            :style="{ color: `${getColor('主题')}` }"
            @click="openModal('下级', record)"
            >新增下级</span
          >
          <span
            class="hover"
            :style="{ color: `${getColor('主题')}` }"
            @click="onDelete(record)"
            >删除</span
          >
        </template>
      </a-table>
    </div>

    <DepartModal
      :departModal="departModal"
      v-if="departModal"
      :config="configFrom"
    />
  </div>
</template>

<script>
const columns = [
  {
    title: "部门名称",
    ellipsis: true,
    dataIndex: "label",
  },
  {
    title: "状态",
    ellipsis: true,
    dataIndex: "state",
    scopedSlots: { customRender: "state" },
  },
  {
    title: "创建时间",
    ellipsis: true,
    dataIndex: "createTime",
  },
  {
    title: "操作",
    ellipsis: true,
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
  },
];
import mixins from "@/mixins/mixins.js";
import pagination from "@/mixins/Pagination.js";
import { getColor } from "@/utils/color";
import { getMenuName } from "@/utils/utils.js";
import DepartModal from "./departModal.vue";
import { APID } from "@/apix/department.js";
import { tips } from "../../utils/tips";

export default {
  mixins: [pagination, mixins],
  components: {
    DepartModal,
  },
  data() {
    return {
      columns,
      data: [
        // {
        //   id: 1,
        //   parentId: null,
        //   label: "中科院",
        //   email: "123456@qq.com",
        //   phone: "12345678901",
        //   principal: "huang",
        //   createTime: "2021-09-13",
        //   state: 1,
        //   children: [
        //     {
        //       id: 2,
        //       parentId: 1,
        //       label: "智能院",
        //       email: "123456@qq.com",
        //       phone: "12345678901",
        //       principal: "huang",
        //       createTime: "2021-09-13",
        //       state: 1,
        //       children: [
        //         {
        //           id: 3,
        //           parentId: 2,
        //           label: "应用研发部",
        //           email: "123456@qq.com",
        //           phone: "12345678901",
        //           principal: "huang",
        //           createTime: "2021-09-13",
        //           state: 1,
        //           children: [
        //             {
        //               id: 4,
        //               parentId: 3,
        //               label: "后端部门",
        //               email: "123456@qq.com",
        //               phone: "12345678901",
        //               principal: "huang",
        //               createTime: "2021-09-13",
        //               state: 1,
        //             },
        //             {
        //               id: 5,
        //               parentId: 3,
        //               label: "前端部门",
        //               email: "123456@qq.com",
        //               phone: "12345678901",
        //               principal: "huang",
        //               createTime: "2021-09-13",
        //               state: 1,
        //             },
        //           ],
        //         },
        //       ],
        //     },
        //   ],
        // },
      ],
      loading: false,
      queryFrom: {
        name: null, // 部门名称
        state: undefined,
      },
      stateList: [
        { name: "停用", value: 0 },
        { name: "正常", value: 1 },
      ],
      departModal: false, // 新增部门
      configFrom: {
        record: null,
        type: null,
      },
      keysArr: [], // 踩坑antdv表格默认展开所有行
    };
  },
  methods: {
    getColor,
    getMenuName,
    getSourceData() {
      this.loading = true;
      APID.pageIndex(this.queryFrom).then((res) => {
        this.data = res.data;
        this.getNewData(this.data);
        this.loading = false;
      });
    },

    // 重置按钮
    handleReset() {
      this.pagination.current = 1;
      Object.keys(this.queryFrom).forEach((key) => {
        this.queryFrom[key] = null;
      });
      this.queryFrom.state = undefined;
      this.getSourceData();
    },
    openModal(type, record) {
      this.configFrom.record = record;
      this.configFrom.type = type;
      this.departModal = true;
    },
    closeModal() {
      this.departModal = false;
    },

    // 部门开关禁用启用
    handleSwitch(val, id) {
      // 调用改变状态接口
      if (val) {
        APID.departmentPass(id).then((res) => {
          tips(res, "启用");
          if (res.success) {
            this.getSourceData();
          }
        });
      } else {
        APID.departmentDisabled(id).then((res) => {
          tips(res, "禁用");
          if (res.success) {
            this.getSourceData();
          }
        });
      }
    },

    // 删除表格项
    onDelete(record) {
      if (this.data.length < 2) {
        this.pagination.current--;
      }
      let self = this;
      this.$confirm({
        content: `是否确认删除名称为 “${record.label}” 的数据项？`,
        cancelText: "取消",
        okText: "确定",
        onOk() {
          self.loading = true;
          APID.departmentDelete(record.id).then((res) => {
            tips(res, "删除");
            if (res.success) {
              self.getSourceData();
            }
            self.loading = false;
          });
        },
      });
    },

    getNewData(arr) {
      arr.map((item) => {
        item.key = item.id;
        this.keysArr.push(item.key);
        if (item.children) {
          this.getNewData(item.children);
        }
        return arr;
      });
    },
  },
  created() {
    this.getSourceData();
  },
};
</script>

<style lang="scss" scoped>
.top-container {
  height: 9rem;
}
.bottom-container {
  min-height: 83%;
}
.hover {
  margin-right: 2rem;
}
.ant-input {
  width: 28.5rem;
}
</style>
