<template>
  <div class="depart-modal-container">
    <a-modal
      :visible="departModal"
      class="modal-style"
      @cancel="cancel"
      @ok="handleOk"
      cancelText="返回"
      :maskClosable="false"
      :width="900"
    >
      <template slot="title">
        <span v-if="config.type === 'edit'">修改部门</span>
        <span v-else>添加部门</span>
      </template>

      <a-form-model
        layout="inline"
        ref="ruleForm"
        :model="submitFrom"
        :rules="rules"
      >
        <div class="layout-inline mb2">
          <a-form-model-item label="上级部门">
            <a-tree-select
              style="width: 28.5rem"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              :tree-data="treeData"
              placeholder="请选择"
              tree-default-expand-all
              :replaceFields="{
                children: 'children',
                title: 'label',
                key: 'key',
                value: 'id',
              }"
              v-model="submitFrom.parentId"
            >
            </a-tree-select>
          </a-form-model-item>
          <a-form-model-item label="部门名称" prop="name">
            <a-input placeholder="请输入" v-model="submitFrom.name"></a-input>
          </a-form-model-item>
        </div>

        <div class="layout-inline mb2">
          <a-form-model-item prop="principal">
            <span slot="label" style="margin-left: 1.3rem">负责人</span>
            <a-input
              placeholder="请输入"
              v-model="submitFrom.principal"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item label="联系电话" prop="phone">
            <a-input placeholder="请输入" v-model="submitFrom.phone"></a-input>
          </a-form-model-item>
        </div>

        <div class="layout-inline mb2">
          <a-form-model-item prop="email">
            <span slot="label" style="margin-left: 2.8rem">邮箱</span>
            <a-input placeholder="请输入" v-model="submitFrom.email"></a-input>
          </a-form-model-item>
          <a-form-model-item label="部门状态" prop="state">
            <a-radio-group
              :default-value="1"
              style="width: 28.5rem"
              :disabled="config.type === 'edit'"
              v-model="submitFrom.state"
            >
              <a-radio :value="1"> 正常 </a-radio>
              <a-radio :value="0"> 停用 </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </div>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import mixins from "@/mixins/mixins.js";
import { departRule } from "@/utils/rulex.js";
import { APID } from "@/apix/department.js";
import { tips } from "../../utils/tips";

export default {
  mixins: [mixins],
  props: {
    departModal: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      rules: departRule,
      submitFrom: {
        parentId: null, // 上级部门
        deptId: null, // 部门id
        name: null, // 部门名称
        principal: null, // 负责人
        phone: null, // 联系电话
        email: null, // 邮箱
        state: null, // 状态（0.停用，1正常）
      },
      treeData: [],
    };
  },
  methods: {
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.config.type === "add" || this.config.type === "下级") {
            this.departmentAdd();
          } else if (this.config.type === "edit") {
            this.departmentEdit();
          }
        }
      });
    },
    // 回填数据
    backFillData(data) {
      for (const key in this.submitFrom) {
        this.submitFrom[key] = data[key];
      }
      this.submitFrom.name = data.label;
    },

    getNewData(arr) {
      arr.map((item) => {
        item.key = item.id;
        if (item.children) {
          this.getNewData(item.children);
        }
        return arr;
      });
    },

    // 拿取部门可用树
    departmentAvaTree() {
      APID.departmentAvaTree().then((res) => {
        this.treeData = res.data;
        this.getNewData(this.treeData);
      });
    },

    // 新增部门
    departmentAdd() {
      APID.departmentAdd(this.submitFrom).then((res) => {
        tips(res, "添加");
        if (res.success) {
          this.cancel();
          this.$parent.getSourceData();
        }
      });
    },

    // 编辑部门
    departmentEdit() {
      APID.departmentEdit(this.submitFrom).then((res) => {
        tips(res, "编辑");
        if (res.success) {
          this.cancel();
          this.$parent.getSourceData();
        }
      });
    },
  },
  created() {
    if (this.config.type === "edit") {
      this.backFillData(this.config.record);
    }
    this.departmentAvaTree();

    if (this.config.type === "add" || this.config.type === "下级") {
      this.submitFrom.parentId = this.config.record
        ? this.config.record.id
        : null;
    } else if (this.config.type === "edit") {
      this.submitFrom.deptId = this.config.record.id;
    }
  },
};
</script>

<style lang="scss" scoped>
.layout-inline {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.ant-input {
  width: 28.5rem;
}
.mb2 {
  margin-bottom: 2rem;
}
.mt2 {
  margin-top: 2rem;
}
/deep/ .ant-form-inline .ant-form-item-with-help {
  margin-bottom: 0;
}
</style>
